import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';

function* watchGetActivites(action) {

    try{
        const result = yield fromApi.activitesGet(action.token).then((data) => {return data})
        yield put({type : 'GET_ACTIVITES_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPutActivite(action) {

    try{
        const result = yield fromApi.activitePut(action.payload, action.token, action.data).then((data) => {return data})
        yield put({type : 'PUT_ACTIVITE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPostActivite(action) {

    try{
        const result = yield fromApi.activitePost(action.token, action.data).then((data) => {return data})
        yield put({type : 'POST_ACTIVITE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchDelActivite(action) {

    try{
        const result = yield fromApi.activiteDel(action.payload, action.token).then((data) => {return data})
        yield put({type : 'DEL_ACTIVITE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* activites(){
    yield takeLatest('GET_ACTIVITES_SAGAS',watchGetActivites)
    yield takeLatest('PUT_ACTIVITE_SAGAS',watchPutActivite)
    yield takeLatest('POST_ACTIVITE_SAGAS',watchPostActivite)
    yield takeLatest('DEL_ACTIVITE_SAGAS',watchDelActivite)
}

export default function* rootSaga() {
    yield all([activites()]);
}