import {toast} from 'react-toastify'

let initialState = {
    infosoffre:[],
    one:[],
    create:[],
    update:[],
    delete:[]
}

const getMandatsInfos = (state = initialState, action) =>{
    switch(action.type){

        case 'GET_MANDATOFFRES_RETURN' : 
           return Object.assign({},state, {infosoffre: action.payload})

        case 'GET_MANDAT_RETURN' : 
           return Object.assign({},state, {one: action.payload})

        case 'POST_MANDAT_RETURN' : 
            const arrayPost = [action.payload.data]
            let newStateAdd
            if (action.payload.status === 201) {
                newStateAdd = {...state.infos, data:state.infos.data.concat(arrayPost)}
                const notifySuccess = () => toast.success("Mandat ajouté avec succès", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifySuccess()
            }
            else{ 
                newStateAdd = state.infos
                if (action.payload.status === 400) {
                    const notifyError = () => toast.error("Erreur ! merci de le signaler...", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    notifyError()
                }
                
            }

           return Object.assign({},state, {
               create: action.payload,
               infos:newStateAdd
            })

        case 'PUT_MANDAT_RETURN' :  
            let array = []
            let newStateUpdate
            let newStateUpdateOne
            if (action.payload.status === 202) {
                array = state.infosoffre.data?.map(item => {
                    return item.uuid === action.payload.data.uuid ? action.payload.data : item;
                })
                newStateUpdate = {...state.infosoffre, data:array}
                newStateUpdateOne = {...state.one, data:action.payload.data}
                const notifySuccess = () => toast.success("Mise à jour réussie !", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifySuccess()
            }else{
                newStateUpdate = state.infosoffre
                const notifyError = () => toast.error("Erreur ! merci de le signaler...", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifyError()
            }
            return Object.assign({},state, {
                update: action.payload,
                infosoffre: newStateUpdate,
                one: newStateUpdateOne
            })

        case 'PUT_OBJET_RETURN' :  
            let arrayObjet = []
            let newStateUpdateObjet
            let newStateUpdateObjetOne
            if (action.payload.status === 202) {
                arrayObjet = state.infosoffre.data?.map(item => {
                    return item.uuid === action.payload.data.uuid ? action.payload.data : item;
                })
                newStateUpdateObjet = {...state.infosoffre, data:arrayObjet}
                newStateUpdateObjetOne = {...state.one, data:action.payload.data}
                const notifySuccess = () => toast.success("Mise à jour enregistrée !", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifySuccess()
            }else{
                newStateUpdateObjet = state.infosoffre
                const notifyError = () => toast.error("Erreur ! merci de le signaler...", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifyError()
            }
            return Object.assign({},state, {
                update: action.payload,
                infosoffre: newStateUpdateObjet,
                one: newStateUpdateObjetOne
            })

        case 'PUT_TAGMANDAT_RETURN' :
            let arrayTag = []
            let newStateUpdateTag
            let newStateUpdateOneTag
            if (action.payload.status === 202) {
                arrayTag = state.infosoffre.data?.map(item => {
                    return item.uuid === action.payload.data.uuid ? action.payload.data : item;
                })
                newStateUpdateTag = {...state.infosoffre, data:arrayTag}
                newStateUpdateOneTag = {...state.one, data:action.payload.data}
                const notifySuccess = () => toast.success("Mise à jour réussie !", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifySuccess()
            }else{
                newStateUpdateTag = state.infosoffre
                const notifyError = () => toast.error("Erreur ! merci de le signaler...", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifyError()
            }
            return Object.assign({},state, {
                update: action.payload,
                infosoffre: newStateUpdateTag,
                one: newStateUpdateOneTag
            })
           
        default:
            return state
    }
}

export default getMandatsInfos