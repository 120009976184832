import { takeLatest, put, all } from "redux-saga/effects";
import * as fromApi from "../../../lib/api/lvtic.api";

function* watchPostImportCommune(action) {
  try {
    const result = yield fromApi
      .importCommunesPost(action.token, action.data)
      .then((data) => {
        return data;
      });
    yield put({ type: "POST_IMPORTCOMMUNE_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}
function* watchGetCommunes(action) {
  try {
    const result = yield fromApi.communesGet(action.token).then((data) => {
      return data;
    });
    yield put({ type: "GET_COMMUNES_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}

function* communes() {
  yield takeLatest("POST_IMPORTCOMMUNE_SAGAS", watchPostImportCommune);
  yield takeLatest("GET_COMMUNES_SAGAS", watchGetCommunes);
}

export default function* rootSaga() {
  yield all([communes()]);
}
