let initialState = {
  infos: [],
  one: [],
  create: [],
  update: [],
  delete: [],
};

const getCommunesInfos = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COMMUNES_RETURN":
      return Object.assign({}, state, { infos: action.payload });

    default:
      return state;
  }
};

export default getCommunesInfos;
