import {toast} from 'react-toastify'

let initialState = {
    infos:[],
    one:[],
    create:[],
    update:[],
    delete:[]
}

const getRecherchesInfos = (state = initialState, action) =>{
    switch(action.type){

        case 'GET_RECHERCHES_RETURN' : 
           return Object.assign({},state, {infos: action.payload})
           
        default:
            return state
    }
}

export default getRecherchesInfos