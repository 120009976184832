export const GET_CONTACTS_RETURN = "GET_CONTACTS_RETURN";
export const GET_CONTACTS_SAGAS = "GET_CONTACTS_SAGAS";

export const GET_CONTACTSPAGINATE_RETURN = "GET_CONTACTSPAGINATE_RETURN";
export const GET_CONTACTSPAGINATE_SAGAS = "GET_CONTACTSPAGINATE_SAGAS";

export const POST_CONTACT_RETURN = "POST_CONTACT_RETURN";
export const POST_CONTACT_SAGAS = "POST_CONTACT_SAGAS";

export const PUT_CONTACT_RETURN = "PUT_CONTACT_RETURN";
export const PUT_CONTACT_SAGAS = "PUT_CONTACT_SAGAS";


//* GET ALL CONTACTS 
export const getContactsSagas = (xAccess) =>({
    type: GET_CONTACTS_SAGAS,
    token: xAccess
})
export const getContactsReturn = (result) =>({
    type: GET_CONTACTS_RETURN,
    payload : result
})

//* GET CONTACTS PAGINATE
export const getContactsPaginateSagas = (xAccess, page, range, cherche) =>({
    type: GET_CONTACTSPAGINATE_SAGAS,
    token: xAccess,
    page, 
    range,
    cherche,
})
export const getContactsPaginateReturn = (result) =>({
    type: GET_CONTACTSPAGINATE_RETURN,
    payload : result
}) 

/* POST CONTACT */
export const postContactSagas = (xAccess, data) =>({
    type: POST_CONTACT_SAGAS,
    token: xAccess,
    data: data
})
export const postContactReturn = (result) =>({
    type: POST_CONTACT_RETURN,
    payload : result
})

/* PUT CONTACT */
export const putContactSagas = (uuid, xAccess, data) =>({
    type: PUT_CONTACT_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putContactReturn = (result) =>({
    type: PUT_CONTACT_RETURN,
    payload : result
})