import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';

function* watchAuthUser(action) {

    try{
        const result = yield fromApi.authUserGet(action.payload, action.token).then((data) => {return data})
        yield put({type : 'AUTH_USER_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* authUserGet(){
    yield takeLatest('AUTH_USER_SAGAS',watchAuthUser)
}

export default function* rootSaga() {
    yield all([authUserGet()]);
}