export const GET_TAGS_RETURN = "GET_TAGS_RETURN";
export const GET_TAGS_SAGAS = "GET_TAGS_SAGAS";

export const PUT_TAG_RETURN = "PUT_TAG_RETURN";
export const PUT_TAG_SAGAS = "PUT_TAG_SAGAS";

export const POST_TAG_RETURN = "POST_TAG_RETURN";
export const POST_TAG_SAGAS = "POST_TAG_SAGAS";

export const DEL_TAG_RETURN = "DEL_TAG_RETURN";
export const DEL_TAG_SAGAS = "DEL_TAG_SAGAS";


//* GET ALL TAGS
export const getTagsSagas = (xAccess) =>({
    type: GET_TAGS_SAGAS,
    token: xAccess
})
export const getTagsReturn = (result) =>({
    type: GET_TAGS_RETURN,
    payload : result
})

//* PUT TAG
export const putTagSagas = (uuid, xAccess, data) =>({
    type: PUT_TAG_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putTagReturn = (result) =>({
    type: PUT_TAG_RETURN,
    payload : result
})

//* POST TAG
export const postTagSagas = (xAccess, data) =>({
    type: POST_TAG_SAGAS,
    token: xAccess,
    data: data
})
export const postTagReturn = (result) =>({
    type: POST_TAG_RETURN,
    payload : result
})

//* DELETE TAG
export const deleteTagSagas = (uuid, xAccess) =>({
    type: DEL_TAG_SAGAS,
    token: xAccess,
    payload: uuid
})
export const deleteTagReturn = (result) =>({
    type: DEL_TAG_RETURN,
    payload : result
})