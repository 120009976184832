export const GET_RECHERCHES_RETURN = "GET_RECHERCHES_RETURN";
export const GET_RECHERCHES_SAGAS = "GET_RECHERCHES_SAGAS";


//* GET ALL RECHERCHES
export const getRecherchesSagas = (xAccess) =>({
    type: GET_RECHERCHES_SAGAS,
    token: xAccess
})
export const getRecherchesReturn = (result) =>({
    type: GET_RECHERCHES_RETURN,
    payload : result
})