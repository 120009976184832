export const GET_ADMINS_RETURN = "GET_ADMINS_RETURN";
export const GET_ADMINS_SAGAS = "GET_ADMINS_SAGAS";

export const GET_ADMIN_RETURN = "GET_ADMIN_RETURN";
export const GET_ADMIN_SAGAS = "GET_ADMIN_SAGAS";

export const GET_ADMINSEND_RETURN = "GET_ADMINSEND_RETURN";
export const GET_ADMINSEND_SAGAS = "GET_ADMINSEND_SAGAS";

export const GET_ADMINDEL_RETURN = "GET_ADMINDEL_RETURN";
export const GET_ADMINDEL_SAGAS = "GET_ADMINDEL_SAGAS";

export const PUT_ADMIN_RETURN = "PUT_ADMIN_RETURN";
export const PUT_ADMIN_SAGAS = "PUT_ADMIN_SAGAS";

export const PUT_ADMINLOGIN_RETURN = "PUT_ADMINLOGIN_RETURN";
export const PUT_ADMINLOGIN_SAGAS = "PUT_ADMINLOGIN_SAGAS";

export const POST_ADMIN_RETURN = "POST_ADMIN_RETURN";
export const POST_ADMIN_SAGAS = "POST_ADMIN_SAGAS";

export const DEL_ADMIN_RETURN = "DEL_ADMIN_RETURN";
export const DEL_ADMIN_SAGAS = "DEL_ADMIN_SAGAS";

//* GET ALL ADMINS
export const getAdminsSagas = (xAccess, archive) =>({
    type: GET_ADMINS_SAGAS,
    token: xAccess,
    archive
})
export const getAdminsReturn = (result) =>({
    type: GET_ADMINS_RETURN,
    payload : result
})

//* GET ONE ADMIN
export const getAdminSagas = (uuid, xAccess) =>({
    type: GET_ADMIN_SAGAS,
    token: xAccess,
    payload: uuid
})
export const getAdminReturn = (result) =>({
    type: GET_ADMIN_RETURN,
    payload : result
})

//* GET ADMIN SEND MAIL
export const getAdminSendSagas = (uuid, xAccess) =>({
    type: GET_ADMINSEND_SAGAS,
    token: xAccess,
    payload: uuid
})
export const getAdminSendReturn = (result) =>({
    type: GET_ADMINSEND_RETURN,
    payload : result
})

//* GET ADMIN DELETE
export const getAdminDelSagas = (uuid, xAccess) =>({
    type: GET_ADMINDEL_SAGAS,
    token: xAccess,
    payload: uuid
})
export const getAdminDelReturn = (result) =>({
    type: GET_ADMINDEL_RETURN,
    payload : result
})

//* PUT ADMIN
export const putAdminSagas = (uuid, xAccess, data) =>({
    type: PUT_ADMIN_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putAdminReturn = (result) =>({
    type: PUT_ADMIN_RETURN,
    payload : result
})

//* PUT ADMIN LOGIN
export const putAdminLoginSagas = (uuid, xAccess, data) =>({
    type: PUT_ADMINLOGIN_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putAdminLoginReturn = (result) =>({
    type: PUT_ADMINLOGIN_RETURN,
    payload : result
})

//* POST ADMIN
export const postAdminSagas = (xAccess, data) =>({
    type: POST_ADMIN_SAGAS,
    token: xAccess,
    data: data
})
export const postAdminReturn = (result) =>({
    type: POST_ADMIN_RETURN,
    payload : result
})

//* DELETE ADMIN
export const deleteAdminSagas = (uuid, xAccess) =>({
    type: DEL_ADMIN_SAGAS,
    token: xAccess,
    payload: uuid
})
export const deleteAdminReturn = (result) =>({
    type: DEL_ADMIN_RETURN,
    payload : result
})