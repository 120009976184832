import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';


function* watchGetContactFonctions(action) {

    try{
        const result = yield fromApi.contactFonctionsGet(action.token).then((data) => {return data})
        yield put({type : 'GET_CONTACTFONCS_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPostContactFonction(action) {

    try{
        const result = yield fromApi.contactFonctionPost(action.token, action.data).then((data) => {return data})
        yield put({type : 'POST_CONTACTFONC_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPutContactFonction(action) {

    try{
        const result = yield fromApi.contactFonctionPut(action.payload, action.token, action.data).then((data) => {return data})
        yield put({type : 'PUT_CONTACTFONC_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchDelContactFonction(action) {

    try{
        const result = yield fromApi.contactFonctionDel(action.payload, action.token).then((data) => {return data})
        yield put({type : 'DEL_CONTACTFONC_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* contactfonctions(){
    yield takeLatest('GET_CONTACTFONCS_SAGAS', watchGetContactFonctions)
    yield takeLatest('POST_CONTACTFONC_SAGAS', watchPostContactFonction)
    yield takeLatest('PUT_CONTACTFONC_SAGAS', watchPutContactFonction)
    yield takeLatest('DEL_CONTACTFONC_SAGAS', watchDelContactFonction)
}

export default function* rootSaga() {
    yield all([contactfonctions()]);
}