export const GET_CANTONS_RETURN = "GET_CANTONS_RETURN";
export const GET_CANTONS_SAGAS = "GET_CANTONS_SAGAS";

export const PUT_CANTON_RETURN = "PUT_CANTON_RETURN";
export const PUT_CANTON_SAGAS = "PUT_CANTON_SAGAS";

export const POST_CANTON_RETURN = "POST_CANTON_RETURN";
export const POST_CANTON_SAGAS = "POST_CANTON_SAGAS";

export const DEL_CANTON_RETURN = "DEL_CANTON_RETURN";
export const DEL_CANTON_SAGAS = "DEL_CANTON_SAGAS";


//* GET ALL CANTONS
export const getCantonsSagas = (xAccess) =>({
    type: GET_CANTONS_SAGAS,
    token: xAccess
})
export const getCantonsReturn = (result) =>({
    type: GET_CANTONS_RETURN,
    payload : result
})

//* PUT CANTON
export const putCantonSagas = (uuid, xAccess, data) =>({
    type: PUT_CANTON_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putCantonReturn = (result) =>({
    type: PUT_CANTON_RETURN,
    payload : result
})

//* POST CANTON
export const postCantonSagas = (xAccess, data) =>({
    type: POST_CANTON_SAGAS,
    token: xAccess,
    data: data
})
export const postCantonReturn = (result) =>({
    type: POST_CANTON_RETURN,
    payload : result
})

//* DELETE CANTON
export const deleteCantonSagas = (uuid, xAccess) =>({
    type: DEL_CANTON_SAGAS,
    token: xAccess,
    payload: uuid
})
export const deleteCantonReturn = (result) =>({
    type: DEL_CANTON_RETURN,
    payload : result
})