import React from "react";
import * as Icon from "react-feather";

export const myConfig = () => {
  console.log();

  const navigationConfig = [
    {
      id: "home",
      title: "Tableau de bord",
      type: "item",
      icon: <Icon.Home size={20} />,
      permissions: ["admin", "editor"],
      navLink: "/",
    },
    {
      type: "groupHeader",
      groupTitle: "Objets & Mandats",
    },
    {
      id: "objet",
      title: "Objets",
      type: "item",
      icon: <i className="fa-regular fa-house-building mr-1"></i>,
      permissions: ["admin", "editor"],
      navLink: "/objets",
    },
    {
      id: "offre",
      title: "Offres",
      type: "item",
      icon: <Icon.Star size={20} />,
      permissions: ["admin", "editor"],
      navLink: "/offres",
    },
    {
      id: "recherche",
      title: "Recherches",
      type: "item",
      icon: <Icon.ZoomIn size={20} />,
      permissions: ["admin", "editor"],
      navLink: "/recherches",
    },
    {
      /*
      id: "demandes",
      title: "Demandes",
      type: "collapse",
      icon: <Icon.MessageCircle size={20} />,
      badge: "success",
      badgeText: '6',
      children: [
        {
          id: "attente",
          title: "A traiter",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/demandes/a-traiter"
        },
        {
          id: "cours",
          title: "En cours",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/demandes/en-cours"
        },
      ]
    */
    },
    {
      type: "groupHeader",
      groupTitle: "Clients - Prospects",
    },
    {
      id: "soc",
      title: "Sociétés",
      type: "collapse",
      icon: <Icon.Package size={20} />,
      children: [
        {
          id: "societes",
          title: "Sociétés",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/societes",
        },
        {
          id: "contacts",
          title: "Contacts",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/contacts",
        },
      ],
    },
    {
      type: "groupHeader",
      groupTitle: "Paramètres",
    },
    {
      id: "parametres",
      title: "Paramètres",
      type: "collapse",
      icon: <Icon.Settings size={20} />,
      children: [
        {
          id: "listes",
          title: "Listes",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/listes",
        },
        {
          id: "import",
          title: "Import",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/import",
        },
      ],
    },
    {
      type: "groupHeader",
      groupTitle: "Utilisateurs",
    },
    {
      id: "admin",
      title: "Utilisateurs",
      type: "item",
      icon: <Icon.Users size={20} />,
      permissions: ["admin", "editor"],
      navLink: "/utilisateurs",
    },
  ];
  return navigationConfig;
};
