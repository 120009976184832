import { toast } from "react-toastify";

let initialState = {
  infos: [],
  one: [],
  create: [],
  update: [],
  delete: [],
};

const getLocauxInfos = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LOCALS_RETURN":
      return Object.assign({}, state, { infos: action.payload });

    case "PUT_LOCAL_RETURN":
      let array = [];
      let newStateUpdate;
      if (action.payload.status === 202) {
        array = state.infos.data?.map((item) => {
          return item.uuid === action.payload.data.uuid
            ? action.payload.data
            : item;
        });
        newStateUpdate = { ...state.infos, data: array };
        const notifySuccess = () =>
          toast.success("Local mis à jour !", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifySuccess();
      } else {
        newStateUpdate = state.infos;
        const notifyError = () =>
          toast.error("Erreur ! merci de le signaler...", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifyError();
      }
      return Object.assign({}, state, {
        update: action.payload,
        infos: newStateUpdate,
      });

    case "POST_LOCAL_RETURN":
      const arrayCats = [action.payload.data];
      let newStateAdd;
      if (action.payload.status === 201) {
        newStateAdd = {
          ...state.infos,
          data: state.infos.data.concat(arrayCats),
        };
        const notifySuccess = () =>
          toast.success("Local ajouté avec succès", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifySuccess();
      } else {
        newStateAdd = state.infos;
        if (action.payload.status === 400) {
          const notifyError = () =>
            toast.error("Erreur ! merci de le signaler...", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          notifyError();
        }
      }

      return Object.assign({}, state, {
        create: action.payload,
        infos: newStateAdd,
      });

    case "DEL_TAG_SAGAS":
      const cantons = state.infos.data;
      const indexCanton = cantons.findIndex(
        (index) => index.uuid === action.payload
      );
      const newStateDelete = {
        ...state.infos,
        data: cantons.filter((item, i) => i !== indexCanton),
      };

      return Object.assign({}, state, { infos: newStateDelete });

    case "DEL_LOCAL_RETURN":
      if (action.payload.status === 202) {
        const notifySuccess = () =>
          toast.success("Tag supprimé !", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifySuccess();
      } else {
        const notifyError = () =>
          toast.error("Erreur !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifyError();
      }
      return Object.assign({}, state, { delete: action.payload });

    default:
      return state;
  }
};

export default getLocauxInfos;
