import { takeLatest, put, all } from "redux-saga/effects";
import * as fromApi from "../../../lib/api/lvtic.api";

function* watchPostObjet(action) {
  try {
    const result = yield fromApi
      .objetPost(action.token, action.data)
      .then((data) => {
        return data;
      });
    yield put({ type: "POST_OBJET_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}
function* watchGetObjets(action) {
  try {
    const result = yield fromApi.objetsGet(action.token).then((data) => {
      return data;
    });
    yield put({ type: "GET_OBJETS_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}
function* watchGetObjet(action) {
  try {
    const result = yield fromApi
      .objetGet(action.payload, action.token)
      .then((data) => {
        return data;
      });
    yield put({ type: "GET_OBJET_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}
function* watchPostMediaObjet(action) {
  try {
    const result = yield fromApi
      .objetMediaPost(action.token, action.data)
      .then((data) => {
        return data;
      });
    yield put({ type: "POST_OBJETMEDIA_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}
function* watchPutObjetMedia(action) {
  try {
    const result = yield fromApi
      .objetMediaOrdrePut(action.payload, action.token, action.data)
      .then((data) => {
        return data;
      });
    yield put({ type: "PUT_OBJETMEDIA_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}

function* objets() {
  yield takeLatest("POST_OBJET_SAGAS", watchPostObjet);
  yield takeLatest("GET_OBJETS_SAGAS", watchGetObjets);
  yield takeLatest("GET_OBJET_SAGAS", watchGetObjet);
  yield takeLatest("POST_OBJETMEDIA_SAGAS", watchPostMediaObjet);
  yield takeLatest("PUT_OBJETMEDIA_SAGAS", watchPutObjetMedia);
}

export default function* rootSaga() {
  yield all([objets()]);
}
