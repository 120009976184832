export const POST_IMPORTCOMMUNE_RETURN = "POST_IMPORTCOMMUNE_RETURN";
export const POST_IMPORTCOMMUNE_SAGAS = "POST_IMPORTCOMMUNE_SAGAS";

export const GET_COMMUNES_RETURN = "GET_COMMUNES_RETURN";
export const GET_COMMUNES_SAGAS = "GET_COMMUNES_SAGAS";

//* POST IMPORT COMMUNE
export const postImportCommuneSagas = (xAccess, data) => ({
  type: POST_IMPORTCOMMUNE_SAGAS,
  token: xAccess,
  data: data,
});
export const postImportCommuneReturn = (result) => ({
  type: POST_IMPORTCOMMUNE_RETURN,
  payload: result,
});

//* GET ALL COMMUNES
export const getCommunesSagas = (xAccess) => ({
  type: GET_COMMUNES_SAGAS,
  token: xAccess,
});
export const getCommunesReturn = (result) => ({
  type: GET_COMMUNES_RETURN,
  payload: result,
});
