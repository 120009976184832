export const GET_LOCALS_RETURN = "GET_LOCALS_RETURN";
export const GET_LOCALS_SAGAS = "GET_LOCALS_SAGAS";

export const PUT_LOCAL_RETURN = "PUT_LOCAL_RETURN";
export const PUT_LOCAL_SAGAS = "PUT_LOCAL_SAGAS";

export const POST_LOCAL_RETURN = "POST_LOCAL_RETURN";
export const POST_LOCAL_SAGAS = "POST_LOCAL_SAGAS";

export const DEL_LOCAL_RETURN = "DEL_LOCAL_RETURN";
export const DEL_LOCAL_SAGAS = "DEL_LOCAL_SAGAS";

//* GET ALL TAGS
export const getLocauxSagas = (xAccess) => ({
  type: GET_LOCALS_SAGAS,
  token: xAccess,
});
export const getLocauxReturn = (result) => ({
  type: GET_LOCALS_RETURN,
  payload: result,
});

//* PUT TAG
export const putLocalSagas = (uuid, xAccess, data) => ({
  type: PUT_LOCAL_SAGAS,
  token: xAccess,
  payload: uuid,
  data: data,
});
export const putLocalReturn = (result) => ({
  type: PUT_LOCAL_RETURN,
  payload: result,
});

//* POST TAG
export const postLocalSagas = (xAccess, data) => ({
  type: POST_LOCAL_SAGAS,
  token: xAccess,
  data: data,
});
export const postLocalReturn = (result) => ({
  type: POST_LOCAL_RETURN,
  payload: result,
});

//* DELETE TAG
export const deleteLocalSagas = (uuid, xAccess) => ({
  type: DEL_LOCAL_SAGAS,
  token: xAccess,
  payload: uuid,
});
export const deleteLocalReturn = (result) => ({
  type: DEL_LOCAL_RETURN,
  payload: result,
});
