import { toast } from "react-toastify";

let initialState = {
  infos: [],
  paginate: [],
  one: [],
  zefix: [],
  searchzefix: [],
  search: [],
  create: [],
  update: [],
  delete: [],
};

const getSocietesInfos = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SOCIETES_RETURN":
      return Object.assign({}, state, { infos: action.payload });

    case "GET_SOCIETESPAGINATE_RETURN":
      return Object.assign({}, state, { paginate: action.payload });

    case "GET_SOCIETE_RETURN":
      return Object.assign({}, state, { one: action.payload });

    case "GET_SEARCH_RETURN":
      return Object.assign({}, state, { search: action.payload });

    case "PUT_SOCIETE_RETURN":
      let array = [];
      let newStateUpdate;
      if (action.payload.status === 202) {
        array = state.infos.data.map((item) => {
          return item.uuid === action.payload.data.uuid
            ? action.payload.data
            : item;
        });
        newStateUpdate = { ...state.infos, data: array };
        const notifySuccess = () =>
          toast.success("Société mise à jour !", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifySuccess();
      } else {
        newStateUpdate = state.infos;
        const notifyError = () =>
          toast.error("Erreur ! merci de le signaler...", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifyError();
      }
      return Object.assign({}, state, {
        update: action.payload,
        infos: newStateUpdate,
      });

    case "POST_SOCIETE_RETURN":
      const arraySoc = [action.payload.data];
      let newStateAdd;
      if (action.payload.status === 201) {
        newStateAdd = {
          ...state.infos,
          data: state.infos.data.concat(arraySoc),
        };
        const notifySuccess = () =>
          toast.success("Société ajouté avec succés", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifySuccess();
      } else {
        newStateAdd = state.infos;
        if (action.payload.status === 400) {
          const notifyError = () =>
            toast.error("Erreur merci de le signaler !", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          notifyError();
        }
      }
      return Object.assign({}, state, {
        create: action.payload,
        infos: newStateAdd,
      });

    case "GET_ZEFIX_RETURN":
      return Object.assign({}, state, { zefix: action.payload });

    case "POST_ZEFIX_RETURN":
      return Object.assign({}, state, { searchzefix: action.payload });

    default:
      return state;
  }
};

export default getSocietesInfos;
