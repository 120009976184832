export const Configs = {
  BearerToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb24iOiJMVlRJQyIsInBob25lIjoiMDY4ODEzNzg0MCIsImVtYWlsIjoiZXJpYy5tb3JlbEBkYWlseS1zb2Z0LmNoIiwicndkIjowLCJpYXQiOjE2NDk3NzMxMzJ9.LGjGX7P75qA1aUi0pJdWzdBneMD272Di8MSunEXe8uk",
  urlApi: "https://api.lvtic.ch",
  urlWeb: "https://www.lvtic.ch",
  typeApi: "production",
  zefixApi: "https://www.zefix.admin.ch/ZefixPublicREST",
  zefixToken: "emVmaXhAZGFpbHktc29mdC5jaDpVeFVaclBteQ==",
  DROPBOX_KEY: "ydqn3ze23phzfdk",
  DROPBOX_SECRET: "",
  DROPBOX_TOKEN: "",
  GOOGLE_KEY: "AIzaSyASZKll-3CV73kBUL4V34O4hGuwSMkByKs",
  adminRole: [
    {
      key: 1,
      value: "Utilisateur",
    },
    {
      key: 2,
      value: "Administrateur",
    },
    {
      key: 3,
      value: "Super Administrateur",
    },
  ],
  societeStatut: [
    {
      key: 0,
      value: "Archivé",
    },
    {
      key: 1,
      value: "Actif",
    },
    {
      key: 2,
      value: "Prospect",
    },
  ],
  formatTel: [
    {
      key: 0,
      libelle: "ch",
      value: "+41",
    },
    {
      key: 1,
      libelle: "fr",
      value: "+33",
    },
  ],
  mandat: [
    {
      key: 0,
      value: "En attente",
      color: "warning",
    },
    {
      key: 1,
      value: "Actif",
      color: "success",
    },
    {
      key: 3,
      value: "Archivé - Résilié",
      color: "danger",
    },
    {
      key: 4,
      value: "Archivé - Trouvé",
      color: "danger",
    },
  ],
  recherche: [
    {
      key: 0,
      value: "En attente",
      color: "warning",
    },
    {
      key: 1,
      value: "Actif",
      color: "success",
    },
    {
      key: 2,
      value: "Prioritaire",
      color: "success",
    },
    {
      key: 3,
      value: "Archivé",
      color: "danger",
    },
    {
      key: 4,
      value: "Trouvé",
      color: "danger",
    },
  ],
  mandatType: [
    {
      key: 0,
      value: "Simple",
    },
    {
      key: 1,
      value: "Semi-exclusif",
    },
    {
      key: 2,
      value: "Co-exclusif",
    },
    {
      key: 3,
      value: "Exclusif",
    },
    {
      key: 4,
      value: "Autre",
    },
  ],
  mandatNature: [
    {
      key: 0,
      value: "",
      color: "",
    },
    {
      key: 1,
      value: "Location",
      color: "info",
    },
    {
      key: 2,
      value: "Vente",
      color: "success",
    },
  ],
  mandatTransaction: [
    {
      key: 0,
      value: "À louer",
      color: "info",
    },
    {
      key: 1,
      value: "À vendre",
      color: "success",
    },
    {
      key: 2,
      value: "À louer ou à vendre",
      color: "danger",
    },
  ],
  rechercheType: [
    {
      key: 0,
      value: "Achat",
    },
    {
      key: 1,
      value: "Location",
    },
    {
      key: 3,
      value: "Achat / Location",
    },
  ],
  rubriques: [
    {
      key: 1,
      value: "Sociétés",
    },
    {
      key: 2,
      value: "Utilisateurs",
    },
    {
      key: 3,
      value: "Paramètres",
    },
  ],
};
