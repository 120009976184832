export const GET_CHAUFFAGES_RETURN = "GET_CHAUFFAGES_RETURN";
export const GET_CHAUFFAGES_SAGAS = "GET_CHAUFFAGES_SAGAS";

export const PUT_CHAUFFAGE_RETURN = "PUT_CHAUFFAGE_RETURN";
export const PUT_CHAUFFAGE_SAGAS = "PUT_CHAUFFAGE_SAGAS";

export const POST_CHAUFFAGE_RETURN = "POST_CHAUFFAGE_RETURN";
export const POST_CHAUFFAGE_SAGAS = "POST_CHAUFFAGE_SAGAS";

export const DEL_CHAUFFAGE_RETURN = "DEL_CHAUFFAGE_RETURN";
export const DEL_CHAUFFAGE_SAGAS = "DEL_CHAUFFAGE_SAGAS";


//* GET ALL CHAUFFAGE
export const getChauffagesSagas = (xAccess) =>({
    type: GET_CHAUFFAGES_SAGAS,
    token: xAccess
})
export const getChauffagesReturn = (result) =>({
    type: GET_CHAUFFAGES_RETURN,
    payload : result
})

//* PUT CHAUFFAGE
export const putChauffageSagas = (uuid, xAccess, data) =>({
    type: PUT_CHAUFFAGE_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putChauffageReturn = (result) =>({
    type: PUT_CHAUFFAGE_RETURN,
    payload : result
})

//* POST CHAUFFAGE
export const postChauffageSagas = (xAccess, data) =>({
    type: POST_CHAUFFAGE_SAGAS,
    token: xAccess,
    data: data
})
export const postChauffageReturn = (result) =>({
    type: POST_CHAUFFAGE_RETURN,
    payload : result
})

//* DELETE CHAUFFAGE
export const deleteChauffageSagas = (uuid, xAccess) =>({
    type: DEL_CHAUFFAGE_SAGAS,
    token: xAccess,
    payload: uuid
})
export const deleteChauffageReturn = (result) =>({
    type: DEL_CHAUFFAGE_RETURN,
    payload : result
})