import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';

function* watchPostSousObjet(action) {

    yield put({type : 'POST_SOUSOBJET_RETURN', payload: action.data})

}


function* sousobjets(){
    yield takeLatest('POST_SOUSOBJET_SAGAS',watchPostSousObjet)
}

export default function* rootSaga() {
    yield all([sousobjets()]);
}