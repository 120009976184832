import { takeLatest, put, all } from "redux-saga/effects";
import * as fromApi from "../../../lib/api/lvtic.api";

function* watchGetLocaux(action) {
  try {
    const result = yield fromApi.localsGet(action.token).then((data) => {
      return data;
    });
    yield put({ type: "GET_LOCALS_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}
function* watchPutLocal(action) {
  try {
    const result = yield fromApi
      .localPut(action.payload, action.token, action.data)
      .then((data) => {
        return data;
      });
    yield put({ type: "PUT_LOCAL_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}
function* watchPostLocal(action) {
  try {
    const result = yield fromApi
      .localPost(action.token, action.data)
      .then((data) => {
        return data;
      });
    yield put({ type: "POST_LOCAL_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}
function* watchDelLocal(action) {
  try {
    const result = yield fromApi
      .localDel(action.payload, action.token)
      .then((data) => {
        return data;
      });
    yield put({ type: "DEL_LOCAL_RETURN", payload: result });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: true,
      payload: error,
    });
  }
}

function* locaux() {
  yield takeLatest("GET_LOCALS_SAGAS", watchGetLocaux);
  yield takeLatest("PUT_LOCAL_SAGAS", watchPutLocal);
  yield takeLatest("POST_LOCAL_SAGAS", watchPostLocal);
  yield takeLatest("DEL_LOCAL_SAGAS", watchDelLocal);
}

export default function* rootSaga() {
  yield all([locaux()]);
}
