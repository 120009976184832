export const GET_CATEGORIES_RETURN = "GET_CATEGORIES_RETURN";
export const GET_CATEGORIES_SAGAS = "GET_CATEGORIES_SAGAS";

export const PUT_CATEGORIE_RETURN = "PUT_CATEGORIE_RETURN";
export const PUT_CATEGORIE_SAGAS = "PUT_CATEGORIE_SAGAS";

export const POST_CATEGORIE_RETURN = "POST_CATEGORIE_RETURN";
export const POST_CATEGORIE_SAGAS = "POST_CATEGORIE_SAGAS";

export const DEL_CATEGORIE_RETURN = "DEL_CATEGORIE_RETURN";
export const DEL_CATEGORIE_SAGAS = "DEL_CATEGORIE_SAGAS";


//* GET ALL CATS
export const getCategoriesSagas = (xAccess) =>({
    type: GET_CATEGORIES_SAGAS,
    token: xAccess
})
export const getCategoriesReturn = (result) =>({
    type: GET_CATEGORIES_RETURN,
    payload : result
})

//* PUT CAT
export const putCategorieSagas = (uuid, xAccess, data) =>({
    type: PUT_CATEGORIE_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putCategorieReturn = (result) =>({
    type: PUT_CATEGORIE_RETURN,
    payload : result
})

//* POST CAT
export const postCategorieSagas = (xAccess, data) =>({
    type: POST_CATEGORIE_SAGAS,
    token: xAccess,
    data: data
})
export const postCategorieReturn = (result) =>({
    type: POST_CATEGORIE_RETURN,
    payload : result
})

//* DELETE CAT
export const deleteMandatCatSagas = (uuid, xAccess) =>({
    type: DEL_CATEGORIE_SAGAS,
    token: xAccess,
    payload: uuid
})
export const deleteMandatCatReturn = (result) =>({
    type: DEL_CATEGORIE_RETURN,
    payload : result
})