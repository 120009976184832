import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';

function* watchGetSocietes(action) {

    try{
        const result = yield fromApi.societesGet(action.token).then((data) => {return data})
        yield put({type : 'GET_SOCIETES_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchGetSocietesPaginate(action) {

    try{
        const result = yield fromApi.societesPaginateGet(action.token, action.page, action.range, action.archive, action.cherche).then((data) => {return data})
        yield put({type : 'GET_SOCIETESPAGINATE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchGetSociete(action) {

    try{
        const result = yield fromApi.societeGet(action.payload, action.token).then((data) => {return data})
        yield put({type : 'GET_SOCIETE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchGetSocietesSearch(action) {

    try{
        const result = yield fromApi.societesSearchGet(action.token, action.data).then((data) => {return data})
        yield put({type : 'GET_SEARCH_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPutSociete(action) {

    try{
        const result = yield fromApi.societePut(action.payload, action.token, action.data).then((data) => {return data})
        yield put({type : 'PUT_SOCIETE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPostSociete(action) {

    try{
        const result = yield fromApi.societePost(action.token, action.data).then((data) => {return data})
        yield put({type : 'POST_SOCIETE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* watchGetZefix(action) {

    try{
        const result = yield fromApi.societeZefixGet(action.payload, action.token).then((data) => {return data})
        yield put({type : 'GET_ZEFIX_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPostZefix(action) {

    try{
        const result = yield fromApi.societeZefixPost(action.token, action.data).then((data) => {return data})
        yield put({type : 'POST_ZEFIX_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* societes(){
    yield takeLatest('GET_SOCIETES_SAGAS', watchGetSocietes)
    yield takeLatest('GET_SOCIETESPAGINATE_SAGAS', watchGetSocietesPaginate)
    yield takeLatest('GET_SOCIETE_SAGAS', watchGetSociete)
    yield takeLatest('GET_SEARCH_SAGAS', watchGetSocietesSearch)
    yield takeLatest('PUT_SOCIETE_SAGAS', watchPutSociete)
    yield takeLatest('POST_SOCIETE_SAGAS', watchPostSociete)
    yield takeLatest('GET_ZEFIX_SAGAS', watchGetZefix)
    yield takeLatest('POST_ZEFIX_SAGAS', watchPostZefix)
}

export default function* rootSaga() {
    yield all([societes()]);
}