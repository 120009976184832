import { toast } from "react-toastify";

let initialState = {
  infos: [],
  one: [],
  create: [],
  update: [],
  delete: [],
};

const getObjetsInfos = (state = initialState, action) => {
  switch (action.type) {
    case "GET_OBJETS_RETURN":
      return Object.assign({}, state, { infos: action.payload });

    case "GET_OBJET_RETURN":
      return Object.assign({}, state, { one: action.payload });

    case "POST_OBJET_RETURN":
    case "POST_OBJETMEDIA_RETURN":
      const arrayPost = [action.payload.data];
      let newStateAdd;
      if (action.payload.status === 201) {
        newStateAdd = {
          ...state.infos,
          data: state.infos.data.concat(arrayPost),
        };
        const notifySuccess = () =>
          toast.success("Enregistrement réussi ", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifySuccess();
      } else {
        newStateAdd = state.infos;
        if (action.payload.status === 400) {
          const notifyError = () =>
            toast.error("Erreur ! merci de le signaler...", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          notifyError();
        }
      }
      return Object.assign({}, state, {
        create: action.payload,
        infos: newStateAdd,
      });

    case "PUT_OBJETMEDIA_RETURN":
      let array = [];
      let newStateUpdate;
      let newStateUpdateOne;
      if (action.payload.status === 201) {
        array = state.infos.data?.map((item) => {
          return item.uuid === action.payload.data.uuid
            ? action.payload.data
            : item;
        });
        newStateUpdate = { ...state.infos, data: array };
        newStateUpdateOne = { ...state.one, data: action.payload.data };
      } else {
        newStateUpdate = state.infos;
        const notifyError = () =>
          toast.error("Erreur ! merci de le signaler...", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifyError();
      }
      return Object.assign({}, state, {
        update: action.payload,
        infos: newStateUpdate,
        one: newStateUpdateOne,
      });

    default:
      return state;
  }
};

export default getObjetsInfos;
