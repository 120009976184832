export const POST_OBJET_RETURN = "POST_OBJET_RETURN";
export const POST_OBJET_SAGAS = "POST_OBJET_SAGAS";

export const GET_OBJETS_RETURN = "GET_OBJETS_RETURN";
export const GET_OBJETS_SAGAS = "GET_OBJETS_SAGAS";

export const GET_OBJET_RETURN = "GET_OBJET_RETURN";
export const GET_OBJET_SAGAS = "GET_OBJET_SAGAS";

//* OBJET MÉDIAS

export const POST_OBJETMEDIA_RETURN = "POST_OBJETMEDIA_RETURN";
export const POST_OBJETMEDIA_SAGAS = "POST_OBJETMEDIA_SAGAS";

export const PUT_OBJETMEDIA_RETURN = "PUT_OBJETMEDIA_RETURN";
export const PUT_OBJETMEDIA_SAGAS = "PUT_OBJETMEDIA_SAGAS";

//* POST OBJET
export const postObjetSagas = (xAccess, data) => ({
  type: POST_OBJET_SAGAS,
  token: xAccess,
  data: data,
});
export const postObjetReturn = (result) => ({
  type: POST_OBJET_RETURN,
  payload: result,
});

//* GET ALL OBJETS
export const getObjetsSagas = (xAccess) => ({
  type: GET_OBJETS_SAGAS,
  token: xAccess,
});
export const getObjetsReturn = (result) => ({
  type: GET_OBJETS_RETURN,
  payload: result,
});

//* GET ONE OBJET
export const getObjetSagas = (uuid, xAccess) => ({
  type: GET_OBJET_SAGAS,
  token: xAccess,
  payload: uuid,
});
export const getObjetReturn = (result) => ({
  type: GET_OBJET_RETURN,
  payload: result,
});

//* POST OBJET MÉDIA
export const postObjetMediaSagas = (xAccess, data) => ({
  type: POST_OBJETMEDIA_SAGAS,
  token: xAccess,
  data: data,
});
export const postObjetMediaReturn = (result) => ({
  type: POST_OBJETMEDIA_RETURN,
  payload: result,
});

//* PUT OBJET MÉDIA
export const putObjetMediaSagas = (uuid, xAccess, data) => ({
  type: PUT_OBJETMEDIA_SAGAS,
  token: xAccess,
  payload: uuid,
  data: data,
});
export const putObjetMediaReturn = (result) => ({
  type: PUT_OBJETMEDIA_RETURN,
  payload: result,
});
