import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';

function* watchGetContacts(action) {

    try{
        const result = yield fromApi.contactsGet(action.token).then((data) => {return data})
        yield put({type : 'GET_CONTACTS_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchGetContactsPaginate(action) {

    try{
        const result = yield fromApi.contactsGet(action.token, action.page, action.range, action.cherche).then((data) => {return data})
        yield put({type : 'GET_CONTACTSPAGINATE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPostContact(action) {

    try{
        const result = yield fromApi.contactPost(action.token, action.data).then((data) => {return data})
        yield put({type : 'POST_CONTACT_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPutContact(action) {

    try{
        const result = yield fromApi.contactPut(action.payload, action.token, action.data).then((data) => {return data})
        yield put({type : 'PUT_CONTACT_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* contacts(){
    yield takeLatest('GET_CONTACTS_SAGAS', watchGetContacts)
    yield takeLatest('GET_CONTACTSPAGINATE_SAGAS', watchGetContactsPaginate)
    yield takeLatest('POST_CONTACT_SAGAS', watchPostContact)
    yield takeLatest('PUT_CONTACT_SAGAS', watchPutContact)
}

export default function* rootSaga() {
    yield all([contacts()]);
}