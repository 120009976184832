import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';

function* watchGetRecheches(action) {

    try{
        const result = yield fromApi.recherchesGet(action.token).then((data) => {return data})
        yield put({type : 'GET_RECHERCHES_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* mandats(){
    yield takeLatest('GET_RECHERCHES_SAGAS',watchGetRecheches)
}

export default function* rootSaga() {
    yield all([mandats()]);
}