//import {toast} from 'react-toastify'

const initialState = {
    infos: [],
    create:[],
    update:[],
    delete:[]
}

const getSousOjetInfos = (state = initialState, action) =>{
    switch(action.type){

        case 'GET_SOUSOBJETS_RETURN' : 
           return Object.assign({},state, {infos: action.payload})

        case 'POST_SOUSOBJET_RETURN' : 
            const arrayPost = [action.payload]
            let newStateAdd
            if(state.infos.data) {
                newStateAdd = {...state.infos, data:state.infos.data.concat(arrayPost)}
            } else {
                newStateAdd = {data:arrayPost}
            }

            return Object.assign({},state, {
                create: action.payload,
                infos: newStateAdd
             })
            

           
        default:
            return state
    }
}

export default getSousOjetInfos