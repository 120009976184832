import {toast} from 'react-toastify'

let initialState = {
    statshome:[],
    statsmandat:[],
    one:[],
    create:[],
    update:[],
    delete:[]
}

const getStatsInfos = (state = initialState, action) =>{
    switch(action.type){
        case 'GET_STATSHOME_RETURN' : 
           return Object.assign({},state, {statshome: action.payload})
        case 'GET_STATSMANDAT_RETURN' : 
           return Object.assign({},state, {statsmandat: action.payload})
           
        default:
            return state
    }
}

export default getStatsInfos