import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';

function* watchPostMandat(action) {

    try{
        const result = yield fromApi.mandatPost(action.token, action.data).then((data) => {return data})
        yield put({type : 'POST_MANDAT_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchGetMandatOffres(action) {

    try{
        const result = yield fromApi.mandatOffresGet(action.token).then((data) => {return data})
        yield put({type : 'GET_MANDATOFFRES_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchGetMandat(action) {

    try{
        const result = yield fromApi.mandatGet(action.payload, action.token).then((data) => {return data})
        yield put({type : 'GET_MANDAT_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPutMandat(action) {

    try{
        const result = yield fromApi.mandatPut(action.payload, action.token, action.data).then((data) => {return data})
        yield put({type : 'PUT_MANDAT_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPutObjet(action) {

    try{
        const result = yield fromApi.mandatObjetPut(action.payload, action.token, action.data).then((data) => {return data})
        yield put({type : 'PUT_OBJET_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPutTagMandat(action) {

    try{
        const result = yield fromApi.mandatTagPut(action.payload, action.token, action.data).then((data) => {return data})
        yield put({type : 'PUT_TAGMANDAT_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* mandats(){
    yield takeLatest('POST_MANDAT_SAGAS',watchPostMandat)
    yield takeLatest('GET_MANDATOFFRES_SAGAS',watchGetMandatOffres)
    yield takeLatest('GET_MANDAT_SAGAS',watchGetMandat)
    yield takeLatest('PUT_MANDAT_SAGAS',watchPutMandat)
    yield takeLatest('PUT_OBJET_SAGAS',watchPutObjet)
    yield takeLatest('PUT_TAGMANDAT_SAGAS',watchPutTagMandat)
}

export default function* rootSaga() {
    yield all([mandats()]);
}