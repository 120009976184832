import {toast} from 'react-toastify'

let initialState = {
    infos:[],
    one:[],
    create:[],
    update:[],
    delete:[]
}

const getContactsInfos = (state = initialState, action) =>{
    switch(action.type){

        case 'GET_CONTACTFONCS_RETURN' : 
           return Object.assign({},state, {infos: action.payload})

        case 'POST_CONTACTFONC_RETURN' : 
        const arrayDatas = [action.payload.data]
        let newStateAdd
        if (action.payload.status === 201) {
            newStateAdd = {...state.infos, data:state.infos.data.concat(arrayDatas)}
            const notifySuccess = () => toast.success("Fonction ajoutée avec succès", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            notifySuccess()
        }
        else{ 
            newStateAdd = state.infos
            if (action.payload.status === 400) {
                const notifyError = () => toast.error("Fonction déjà enregistrée !", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifyError()
            }
            
        }
        return Object.assign({},state, {
           create: action.payload,
           infos: newStateAdd
        })

        case 'PUT_CONTACTFONC_RETURN' : 
            let array = []
            let newStateUpdate
            if (action.payload.status === 202) {
                array = state.infos.data?.map(item => {
                    return item.uuid === action.payload.data.uuid ? action.payload.data : item;
                })
                newStateUpdate = {...state.infos, data:array}
                const notifySuccess = () => toast.success("Fonction mise à jour !", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifySuccess()
            }else{
                newStateUpdate = state.infos
                const notifyError = () => toast.error("Erreur ! merci de le signaler...", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifyError()
            }
            return Object.assign({},state, {
                update: action.payload,
                infos: newStateUpdate
            })

        case 'DEL_CONTACTFONC_SAGAS':
            const fonctions = state.infos.data
            const indexFonction = fonctions.findIndex(index => index.uuid === action.payload);
            const newStateDelete = {...state.infos, data: fonctions.filter((item, i) => i !== indexFonction )}
            
            return Object.assign({},state,{infos: newStateDelete})

        case 'DEL_CONTACTFONC_RETURN' : 

            if (action.payload.status === 202) {
                const notifySuccess = () => toast.success("Fonction supprimée !", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifySuccess()
            }
            else{ 
                const notifyError = () => toast.error("Erreur !", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifyError()
            }
            return Object.assign({},state, {delete: action.payload})
           
        default:
            return state
    }
}

export default getContactsInfos