import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';

function* watchGetStatsHome(action) {

    try{
        const result = yield fromApi.statsHomeGet(action.token).then((data) => {return data})
        yield put({type : 'GET_STATSHOME_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchGetStatsMandat(action) {

    try{
        const result = yield fromApi.statsMandatGet(action.token).then((data) => {return data})
        yield put({type : 'GET_STATSMANDAT_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* stats(){
    yield takeLatest('GET_STATSHOME_SAGAS',watchGetStatsHome)
    yield takeLatest('GET_STATSMANDAT_SAGAS',watchGetStatsMandat)
}

export default function* rootSaga() {
    yield all([stats()]);
}