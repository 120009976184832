import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import login from "./auth/login.reducer";
import authUser from "./auth/user.reducer";
import admins from "./admins/admins.reducer";
import societes from "./societes/societes.reducer";
import contacts from "./contacts/contacts.reducer";
import contactfonctions from "./contacts/contactfonction.reducer";
import cantons from "./cantons/cantons.reducer";
import tags from "./tags/tags.reducer";
import chauffages from "./chauffages/chauffages.reducer";
import mandats from "./mandats/mandats.reducer";
import categories from "./categories/categories.reducer";
import sousobjets from "./mandats/sousObjets.reducer";
import recherches from "./recherches/recherches.reducer";
import objets from "./objets/objets.reducer";
import stats from "./stats/stats.reducer";
import activites from "./activites/activites.reducer";
import communes from "./communes/communes.reducer";
import locaux from "./locaux/locaux.reducer";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  login: login,
  authUser: authUser,
  admins: admins,
  societes: societes,
  contacts: contacts,
  contactfonctions: contactfonctions,
  cantons: cantons,
  tags: tags,
  chauffages: chauffages,
  mandats: mandats,
  categories: categories,
  sousobjets: sousobjets,
  recherches: recherches,
  objets: objets,
  stats: stats,
  activites: activites,
  communes: communes,
  locaux: locaux,
});

export default rootReducer;
