import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';

function* watchGetTags(action) {

    try{
        const result = yield fromApi.tagsGet(action.token).then((data) => {return data})
        yield put({type : 'GET_TAGS_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPutTag(action) {

    try{
        const result = yield fromApi.tagPut(action.payload, action.token, action.data).then((data) => {return data})
        yield put({type : 'PUT_TAG_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPostTag(action) {

    try{
        const result = yield fromApi.tagPost(action.token, action.data).then((data) => {return data})
        yield put({type : 'POST_TAG_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchDelTag(action) {

    try{
        const result = yield fromApi.tagDel(action.payload, action.token).then((data) => {return data})
        yield put({type : 'DEL_TAG_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* tags(){
    yield takeLatest('GET_TAGS_SAGAS',watchGetTags)
    yield takeLatest('PUT_TAG_SAGAS',watchPutTag)
    yield takeLatest('POST_TAG_SAGAS',watchPostTag)
    yield takeLatest('DEL_TAG_SAGAS',watchDelTag)
}

export default function* rootSaga() {
    yield all([tags()]);
}