import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';

function* watchGetCategories(action) {

    try{
        const result = yield fromApi.categoriesGet(action.token).then((data) => {return data})
        yield put({type : 'GET_CATEGORIES_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPutCategorie(action) {

    try{
        const result = yield fromApi.categoriePut(action.payload, action.token, action.data).then((data) => {return data})
        yield put({type : 'PUT_CATEGORIE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPostCategorie(action) {

    try{
        const result = yield fromApi.categoriePost(action.token, action.data).then((data) => {return data})
        yield put({type : 'POST_CATEGORIE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchDelCategorie(action) {

    try{
        const result = yield fromApi.categorieDel(action.payload, action.token).then((data) => {return data})
        yield put({type : 'DEL_CATEGORIE_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* categories(){
    yield takeLatest('GET_CATEGORIES_SAGAS',watchGetCategories)
    yield takeLatest('PUT_CATEGORIE_SAGAS',watchPutCategorie)
    yield takeLatest('POST_CATEGORIE_SAGAS',watchPostCategorie)
    yield takeLatest('DEL_CATEGORIE_SAGAS',watchDelCategorie)
}

export default function* rootSaga() {
    yield all([categories()]);
}