export const POST_MANDAT_RETURN = "POST_MANDAT_RETURN";
export const POST_MANDAT_SAGAS = "POST_MANDAT_SAGAS";

export const GET_MANDATOFFRES_RETURN = "GET_MANDATOFFRES_RETURN";
export const GET_MANDATOFFRES_SAGAS = "GET_MANDATOFFRES_SAGAS";

export const GET_MANDAT_RETURN = "GET_MANDAT_RETURN";
export const GET_MANDAT_SAGAS = "GET_MANDAT_SAGAS";

export const PUT_MANDAT_RETURN = "PUT_MANDAT_RETURN";
export const PUT_MANDAT_SAGAS = "PUT_MANDAT_SAGAS";

export const PUT_OBJET_RETURN = "PUT_OBJET_RETURN";
export const PUT_OBJET_SAGAS = "PUT_OBJET_SAGAS";

export const PUT_TAGMANDAT_RETURN = "PUT_MANDPUT_TAGMANDAT_RETURNAT_RETURN";
export const PUT_TAGMANDAT_SAGAS = "PUT_TAGMANDAT_SAGAS";


//* POST MANDAT
export const postMandatSagas = (xAccess, data) =>({
    type: POST_MANDAT_SAGAS,
    token: xAccess,
    data: data
})
export const postMandatReturn = (result) =>({
    type: POST_MANDAT_RETURN,
    payload : result
})

//* GET ALL MANDATS OFFRE
export const getMandatOffresSagas = (xAccess) =>({
    type: GET_MANDATOFFRES_SAGAS,
    token: xAccess
})
export const getMandatOffresReturn = (result) =>({
    type: GET_MANDATOFFRES_RETURN,
    payload : result
})

//* GET ONE MANDAT
export const getMandatSagas = (uuid, xAccess) =>({
    type: GET_MANDAT_SAGAS,
    token: xAccess,
    payload: uuid
})
export const getMandatReturn = (result) =>({
    type: GET_MANDAT_RETURN,
    payload : result
})

//* PUT MANDAT
export const putMandatSagas = (uuid, xAccess, data) =>({
    type: PUT_MANDAT_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putMandatReturn = (result) =>({
    type: PUT_MANDAT_RETURN,
    payload : result
})

//* PUT OBJET
export const putObjetSagas = (uuid, xAccess, data) =>({
    type: PUT_OBJET_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putObjetReturn = (result) =>({
    type: PUT_OBJET_RETURN,
    payload : result
})

//* PUT MANDAT TAGS
export const putTagsMandatSagas = (uuid, xAccess, data) =>({
    type: PUT_TAGMANDAT_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putTagsMandatReturn = (result) =>({
    type: PUT_TAGMANDAT_RETURN,
    payload : result
})