export const GET_SOCIETES_RETURN = "GET_SOCIETES_RETURN";
export const GET_SOCIETES_SAGAS = "GET_SOCIETES_SAGAS";

export const GET_SOCIETESPAGINATE_RETURN = "GET_SOCIETESPAGINATE_RETURN";
export const GET_SOCIETESPAGINATE_SAGAS = "GET_SOCIETESPAGINATE_SAGAS";

export const GET_SOCIETE_RETURN = "GET_SOCIETE_RETURN";
export const GET_SOCIETE_SAGAS = "GET_SOCIETE_SAGAS";

export const GET_SEARCH_RETURN = "GET_SEARCH_RETURN";
export const GET_SEARCH_SAGAS = "GET_SEARCH_SAGAS";

export const PUT_SOCIETE_RETURN = "PUT_SOCIETE_RETURN";
export const PUT_SOCIETE_SAGAS = "PUT_SOCIETE_SAGAS";

export const POST_SOCIETE_RETURN = "POST_SOCIETE_RETURN";
export const POST_SOCIETE_SAGAS = "POST_SOCIETE_SAGAS";

export const POST_SEARCHSOCIETE_RETURN = "POST_SEARCHSOCIETE_RETURN";
export const POST_SEARCHSOCIETE_SAGAS = "POST_SEARCHSOCIETE_SAGAS";

export const GET_ZEFIX_RETURN = "GET_ZEFIX_RETURN";
export const GET_ZEFIX_SAGAS = "GET_ZEFIX_SAGAS";

export const POST_ZEFIX_RETURN = "POST_ZEFIX_RETURN";
export const POST_ZEFIX_SAGAS = "POST_ZEFIX_SAGAS";


/* GET ALL SOCIETES PAGINATE */
export const getSocietesSagas = (xAccess) =>({
    type: GET_SOCIETES_SAGAS,
    token: xAccess
})
export const getSocietesReturn = (result) =>({
    type: GET_SOCIETES_RETURN,
    payload : result
})

/* GET ALL SOCIETES PAGINATE */
export const getSocietesPaginateSagas = (xAccess, page, range, archive, cherche) =>({
    type: GET_SOCIETESPAGINATE_SAGAS,
    token: xAccess,
    page, 
    range,
    archive,
    cherche,
})
export const getSocietesPaginateReturn = (result) =>({
    type: GET_SOCIETESPAGINATE_RETURN,
    payload : result
})

/* GET ONE SOCIETE */
export const getSocieteSagas = (uuid, xAccess) =>({
    type: GET_SOCIETE_SAGAS,
    token: xAccess,
    payload: uuid
})
export const getSocieteReturn = (result) =>({
    type: GET_SOCIETE_RETURN,
    payload : result
})

/* GET SEARCH SOCIETE */
export const getSocietsSearchSagas = (xAccess, data) =>({
    type: GET_SEARCH_SAGAS,
    token: xAccess,
    data: data
})
export const getSocietesSearchReturn = (result) =>({
    type: GET_SEARCH_RETURN,
    payload : result
})

/* PUT SOCIETE */
export const putSocieteSagas = (uuid, xAccess, data) =>({
    type: PUT_SOCIETE_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putSocieteReturn = (result) =>({
    type: PUT_SOCIETE_RETURN,
    payload : result
})

/* POST SOCIETE */
export const postSocieteSagas = (xAccess, data) =>({
    type: POST_SOCIETE_SAGAS,
    token: xAccess,
    data: data
})
export const postSocieteReturn = (result) =>({
    type: POST_SOCIETE_RETURN,
    payload : result
})

/* GET ONE ZEFIX */
export const getZefixSagas = (id, xAccess) =>({
    type: GET_ZEFIX_SAGAS,
    token: xAccess,
    payload: id
})
export const getZefixReturn = (result) =>({
    type: GET_ZEFIX_RETURN,
    payload : result
})

/* POST ZEFIX */
export const postZefixSagas = (xAccess, data) =>({
    type: POST_ZEFIX_SAGAS,
    token: xAccess,
    data: data
})
export const postZefixReturn = (result) =>({
    type: POST_ZEFIX_RETURN,
    payload : result
})