export const GET_ACTIVITES_RETURN = "GET_ACTIVITES_RETURN";
export const GET_ACTIVITES_SAGAS = "GET_ACTIVITES_SAGAS";

export const PUT_ACTIVITE_RETURN = "PUT_ACTIVITE_RETURN";
export const PUT_ACTIVITE_SAGAS = "PUT_ACTIVITE_SAGAS";

export const POST_ACTIVITE_RETURN = "POST_ACTIVITE_RETURN";
export const POST_ACTIVITE_SAGAS = "POST_ACTIVITE_SAGAS";

export const DEL_ACTIVITE_RETURN = "DEL_ACTIVITE_RETURN";
export const DEL_ACTIVITE_SAGAS = "DEL_ACTIVITE_SAGAS";


//* GET ALL ACTIVITES
export const getActivitesSagas = (xAccess) =>({
    type: GET_ACTIVITES_SAGAS,
    token: xAccess
})
export const getActivitesReturn = (result) =>({
    type: GET_ACTIVITES_RETURN,
    payload : result
})

//* PUT ACTIVITE
export const putActiviteSagas = (uuid, xAccess, data) =>({
    type: PUT_ACTIVITE_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putActiviteReturn = (result) =>({
    type: PUT_ACTIVITE_RETURN,
    payload : result
})

//* POST ACTIVITE
export const postActiviteSagas = (xAccess, data) =>({
    type: POST_ACTIVITE_SAGAS,
    token: xAccess,
    data: data
})
export const postActiviteReturn = (result) =>({
    type: POST_ACTIVITE_RETURN,
    payload : result
})

//* DELETE ACTIVITE
export const deleteActiviteSagas = (uuid, xAccess) =>({
    type: DEL_ACTIVITE_SAGAS,
    token: xAccess,
    payload: uuid
})
export const deleteActiviteReturn = (result) =>({
    type: DEL_ACTIVITE_RETURN,
    payload : result
})