export const GET_CONTACTFONCS_RETURN = "GET_CONTACTFONCS_RETURN";
export const GET_CONTACTFONCS_SAGAS = "GET_CONTACTFONCS_SAGAS";

export const POST_CONTACTFONC_RETURN = "POST_CONTACTFONC_RETURN";
export const POST_CONTACTFONC_SAGAS = "POST_CONTACTFONC_SAGAS";

export const PUT_CONTACTFONC_RETURN = "PUT_CONTACTFONC_RETURN";
export const PUT_CONTACTFONC_SAGAS = "PUT_CONTACTFONC_SAGAS";

export const DEL_CONTACTFONC_RETURN = "DEL_CONTACTFONC_RETURN";
export const DEL_CONTACTFONC_SAGAS = "DEL_CONTACTFONC_SAGAS";


/* GET ALL FONCTIONS */
export const getContactFonctionsSagas = (xAccess) =>({
    type: GET_CONTACTFONCS_SAGAS,
    token: xAccess
})
export const getContactFonctionsReturn = (result) =>({
    type: GET_CONTACTFONCS_RETURN,
    payload : result
})

/* POST CONTACT FONCTION */
export const postContactFonctionSagas = (xAccess, data) =>({
    type: POST_CONTACTFONC_SAGAS,
    token: xAccess,
    data: data
})
export const postContactFonctionReturn = (result) =>({
    type: POST_CONTACTFONC_RETURN,
    payload : result
})

//* PUT CONTACT FONCTION
export const putContactFonctionSagas = (uuid, xAccess, data) =>({
    type: PUT_CONTACTFONC_SAGAS,
    token: xAccess,
    payload: uuid,
    data: data
})
export const putContactFonctionReturn = (result) =>({
    type: PUT_CONTACTFONC_RETURN,
    payload : result
})

//* DELETE CONTACT FONCTION
export const deleteContactFonctionSagas = (uuid, xAccess) =>({
    type: DEL_CONTACTFONC_SAGAS,
    token: xAccess,
    payload: uuid
})
export const deleteContactFonctionReturn = (result) =>({
    type: DEL_CONTACTFONC_RETURN,
    payload : result
})