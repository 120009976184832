import { toast } from "react-toastify";

let initialState = {
  infos: [],
  paginate: [],
  one: [],
  create: [],
  update: [],
  delete: [],
};

const getContactsInfos = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTACTS_RETURN":
      return Object.assign({}, state, { infos: action.payload });

    case "GET_CONTACTSPAGINATE_RETURN":
      return Object.assign({}, state, { paginate: action.payload });

    case "POST_CONTACT_RETURN":
      let newStateAdd;
      if (action.payload.status === 201) {
        newStateAdd = { ...state.infos, data: action.payload.data };
        const notifySuccess = () =>
          toast.success("Contact ajouté avec succés", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifySuccess();
      } else {
        if (action.payload.status === 400) {
          const notifyError = () =>
            toast.error("Email déjà enregistré !", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          notifyError();
        }
      }

      return Object.assign({}, state, {
        create: action.payload,
        infos: newStateAdd,
      });

    case "PUT_CONTACT_RETURN":
      if (action.payload.status === 202) {
        const notifySuccess = () =>
          toast.success("Contact mis à jour !", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifySuccess();
      } else {
        const notifyError = () =>
          toast.error("Erreur ! merci de le signaler...", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        notifyError();
      }
      return Object.assign({}, state, {
        update: action.payload,
      });

    default:
      return state;
  }
};

export default getContactsInfos;
