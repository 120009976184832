import {
    takeLatest,
    put,
    all,
   
} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/lvtic.api';

function* watchGetCantons(action) {

    try{
        const result = yield fromApi.cantonsGet(action.token).then((data) => {return data})
        yield put({type : 'GET_CANTONS_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPutCanton(action) {

    try{
        const result = yield fromApi.cantonPut(action.payload, action.token, action.data).then((data) => {return data})
        yield put({type : 'PUT_CANTON_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchPostCanton(action) {

    try{
        const result = yield fromApi.cantonPost(action.token, action.data).then((data) => {return data})
        yield put({type : 'POST_CANTON_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}
function* watchDelCanton(action) {

    try{
        const result = yield fromApi.cantonDel(action.payload, action.token).then((data) => {return data})
        yield put({type : 'DEL_CANTON_RETURN', payload: result})
        
    }catch(error){
        yield put({
            type : 'ERROR',
            error : true,
            payload : error
        })
        
    }
}

function* cantons(){
    yield takeLatest('GET_CANTONS_SAGAS',watchGetCantons)
    yield takeLatest('PUT_CANTON_SAGAS',watchPutCanton)
    yield takeLatest('POST_CANTON_SAGAS',watchPostCanton)
    yield takeLatest('DEL_CANTON_SAGAS',watchDelCanton)
}

export default function* rootSaga() {
    yield all([cantons()]);
}