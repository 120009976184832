import { fork } from "redux-saga/effects";
import loginUserSagas from "./auth/login.saga";
import authUserSagas from "./auth/user.saga";
import adminsSagas from "./admins/admins.saga";
import societesSagas from "./societes/societes.saga";
import contactsSagas from "./contacts/contacts.saga";
import contactFonctionsSagas from "./contacts/contactfonction.saga";
import cantonsSagas from "./cantons/cantons.saga";
import tagsSagas from "./tags/tags.saga";
import chauffagesSagas from "./chauffages/chauffages.saga";
import mandatSaga from "./mandats/mandats.saga";
import categoriesSagas from "./categories/categories.saga";
import sousObjetSaga from "./mandats/sousObjets.saga";
import rechercheSaga from "./recherches/recherches.saga";
import statsSagas from "./stats/stats.saga";
import objetsSagas from "./objets/objets.saga";
import activitesSagas from "./activites/activites.saga";
import communesSagas from "./communes/communes.saga";
import locauxSagas from "./locaux/locaux.saga";

function* rootSaga() {
  yield fork(loginUserSagas);
  yield fork(authUserSagas);
  yield fork(adminsSagas);
  yield fork(societesSagas);
  yield fork(contactsSagas);
  yield fork(contactFonctionsSagas);
  yield fork(cantonsSagas);
  yield fork(tagsSagas);
  yield fork(chauffagesSagas);
  yield fork(mandatSaga);
  yield fork(categoriesSagas);
  yield fork(sousObjetSaga);
  yield fork(rechercheSaga);
  yield fork(statsSagas);
  yield fork(objetsSagas);
  yield fork(activitesSagas);
  yield fork(communesSagas);
  yield fork(locauxSagas);
}

export default rootSaga;
