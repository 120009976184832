import axios from "axios";
import { Configs } from "../../configs/general.config";

/* IDENTIFICATION */

export const loginUser = async (data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/authenticator/admin`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const authUserGet = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/admin/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};

/* ADMINISTRATEURS */

export const adminsGet = async (xAccess, archive) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/admins?archive=${archive}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const adminGet = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/admin/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const adminDeleteGet = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/admindelete/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const adminPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/admin`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const adminPut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/admin/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const adminDel = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "delete",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/admin/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const sendMailGet = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/admin/sendMail/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};

/* SOCIETES */

export const societesGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/societes`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const societesPaginateGet = async (
  xAccess,
  page,
  range,
  archive,
  cherche
) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/societes/paginate?page=${page}&size=${range}&archive=${archive}&cherche=${cherche}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const societeGet = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/societe/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const societesSearchGet = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/societes/search`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const societePut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/societe/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const societePost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/societe`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};

export const societeZefixGet = async (id, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/zefix/${id}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const societeZefixPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/zefix/search`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};

/* CONTACTS */

export const contactPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/contact`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const contactsGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/contacts`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const contactsGetPaginate = async (xAccess, page, range, cherche) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/contacts/paginate?page=${page}&size=${range}&cherche=${cherche}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const contactPut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/contact/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const contactDetailPut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/contact/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* CONTACT FONCTIONS */

export const contactFonctionPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/contactfonction`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const contactFonctionsGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/contactfonctions`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const contactFonctionPut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/contactfonction/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const contactFonctionDel = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "delete",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/contactfonction/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* CANTONS */

export const cantonPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/canton`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const cantonsGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/cantons`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const cantonPut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/canton/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const cantonDel = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "delete",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/canton/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* TAGS */

export const tagPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/tag`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const tagsGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/tags`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const tagPut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/tag/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const tagDel = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "delete",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/tag/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* LOCAUX */

export const localPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/local`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const localsGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/locals`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const localPut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/local/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const localDel = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "delete",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/local/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* CHAUFFAGE */

export const chauffagePost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/chauffage`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const chauffagesGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/chauffages`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const chauffagePut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/chauffage/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const chauffageDel = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "delete",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/chauffage/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* ACTIVITES */

export const activitePost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/activite`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const activitesGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/activites`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const activitePut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/activite/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const activiteDel = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "delete",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/activite/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* MANDATS */

export const mandatPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/mandat`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const mandatOffresGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/mandats/offre`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const mandatGet = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/mandat/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const mandatPut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/mandat/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const mandatObjetPut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/mandat/objet/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* CATEGORIES */

export const categoriePost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/categorie`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const categoriesGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/categories`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const categoriePut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/categorie/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};
export const categorieDel = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "delete",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/categorie/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* MANDATS TAGS */

export const mandatTagPut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/mandattags/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* RECHERCHES */

export const recherchesGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/recherches`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};

/* OBJETS */

export const objetPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/objet`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const objetsGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/objets`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const objetGet = async (uuid, xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/objet/${uuid}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const objetMediaPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/objetmedia`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const objetMediaOrdrePut = async (uuid, xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "put",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/objetmedia/ordre/${uuid}`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }
  return results;
};

/* STATS */

export const statsHomeGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/statshome`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const statsMandatGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/statsmandat`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};

/* COMMUNES */

export const importCommunesPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/commune/import`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
export const communesGet = async (xAccess) => {
  let results = [];
  try {
    await axios({
      method: "get",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/communes`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};

/* MEDIAS */

export const mediaPost = async (xAccess, data) => {
  let results = [];
  try {
    await axios({
      method: "post",
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/objet`,
      data: data,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": xAccess,
      },
    })
      .then((response) => (results = response))
      .catch((error) => (results = error.response));
  } catch (error) {
    results = error.response;
  }

  return results;
};
